/* eslint-disable no-underscore-dangle */
import {AfterViewInit, Component, ElementRef, OnDestroy, ViewChild} from "@angular/core";
import {Chart, ChartType, registerables, Tooltip, TooltipPositionerMap} from "chart.js";
import {TreemapController, TreemapElement} from "chartjs-chart-treemap";
import {Store} from "@ngrx/store";
import {combineLatest, Subscription} from "rxjs";
import {selectSelectedFundId, selectTreemapDataFromPartnershipInvestment} from "../../store/fund/fund.selectors";
import {DecimalFormatPipe} from "../../../shared/pipes/decimal-format/decimal-format.pipe";
import {TraceableFormatPipe} from "../../../shared/pipes/traceable-format/traceable-format.pipe";
import {Router} from "@angular/router";
import {selectSelectedFundReportId} from "../../store/fund-report/fund-report.selectors";
import {selectSelectedFundValuationId} from "../../store/fund-valuation/fund-valuation.selectors";

@Component({
    selector: "valumize-fund-treemap-actual",
    templateUrl: "./fund-treemap-actual.component.html",
    styleUrls: ["./fund-treemap-actual.component.scss"]
})
export class FundTreemapActualComponent implements AfterViewInit, OnDestroy {

    @ViewChild("fundTreemap") fundTreemap: ElementRef | undefined;


    fundId$ = this.store.select(selectSelectedFundId);
    fundReportId$ = this.store.select(selectSelectedFundReportId);
    fundValuationId$ = this.store.select(selectSelectedFundValuationId);
    treemapDataFromPartnershipInvestment$ = this.store.select(selectTreemapDataFromPartnershipInvestment);

    subscriptions: Subscription[] = [];

    fundId?: number;
    fundReportId?: number;
    fundValuationId?: number;

    private chart: Chart | undefined;

    constructor(
        private readonly store: Store,
        private readonly decimalFormatPipe: DecimalFormatPipe,
        private readonly traceableFormatPipe: TraceableFormatPipe,
        private readonly router: Router
    ) {
    }

    ngAfterViewInit(): void {
        this.subscriptions.push(
            combineLatest([
                this.fundId$,
                this.fundReportId$,
                this.fundValuationId$,
                this.treemapDataFromPartnershipInvestment$
            ]).subscribe(([fundId, fundReportId, fundValuationId, treemapData]) => {
                this.fundId = fundId;
                this.fundReportId = fundReportId;
                this.fundValuationId = fundValuationId;
                if (this.chart) {
                    this.chart.destroy();
                }
                if (treemapData.length > 0 && !!this.fundTreemap) {
                    this.chart = this.createTreemap(this.transformData(treemapData), this.fundTreemap.nativeElement);
                }
            })
        );
    }

    transformData(data: any[]): any[] {
        return data.map((item, index) => ({
            assetId: item.assetId,
            fundInvestmentId: item.fundInvestmentId,
            assetValuationId: item.assetValuationId,
            company: item.name.text,
            navMultiple: this.traceableFormatPipe.transform(item.navMultiple),
            percentageOfTotalNAV: this.traceableFormatPipe.transform(item.percentageOfTotalNAV),
            gpNAV: this.traceableFormatPipe.transform(item.gpNAV),
            remainingCost: this.traceableFormatPipe.transform(item.remainingCost),
            realizedTVPI: this.traceableFormatPipe.transform(item.realizedTVPI),
            unrealizedTVPI: this.traceableFormatPipe.transform(item.unrealizedTVPI),
            discountToNAV: this.traceableFormatPipe.transform(item.discountToNAV),
            color: this.getColor(index)
        }));
    }

    createTreemap(data: any[], ctx: HTMLCanvasElement): Chart {
        Chart.register(
            Tooltip,
            TreemapController,
            TreemapElement,
            ...registerables
        );

        const config = {
            type: "treemap" as ChartType,
            data: {
                datasets: [
                    {
                        data: [],
                        tree: data,
                        key: "gpNAV",
                        borderWidth: 0,
                        borderRadius: 6,
                        spacing: 1,
                        backgroundColor: (item: any) => {
                            if (item.type !== "data") {
                                return "transparent";
                            }
                            return item.raw._data.color;
                        },
                        labels: {
                            align: "center",
                            display: true,
                            formatter: (item: any) => {
                                if (item.type !== "data") {
                                    return;
                                }
                                return [item.raw._data.company, item.raw._data.navMultiple];
                            },
                            color: ["black", "black"],
                            font: [{size: 20, weight: "bold"}, {size: 20}],
                            position: "center"
                        }
                    }
                ],
            },
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        enabled: (context: any) => context.mode !== "click",
                        position: "average" as keyof TooltipPositionerMap,
                        callbacks: {
                            title: () => "",
                            label: (item: any) => {
                                const dataItem = item.raw;
                                const obj = dataItem._data;
                                const label = obj.company;
                                return [
                                    `${label}`,
                                    `Percentage of total NAV: ${obj.percentageOfTotalNAV}`,
                                    `GP NAV: ${obj.gpNAV}`,
                                    `Remaining Cost: ${obj.remainingCost}`,
                                    `Realized TVPI: ${obj.realizedTVPI}`,
                                    `Unrealized TVPI: ${obj.unrealizedTVPI}`,
                                    `Discount to NAV: ${obj.discountToNAV}`
                                ];
                            }
                        }
                    }
                }
            }
        };

        const chart = new Chart(ctx, config);

        ctx.onclick = (event: MouseEvent) => {
            const elements = chart.getElementsAtEventForMode(event, "nearest", {intersect: true}, true);
            if (elements.length) {
                const element = elements[0] as any;
                if (element && element.element && element.element.$context && element.element.$context.raw) {
                    const assetData = element.element.$context.raw._data;
                    if (assetData && assetData.assetId) {
                        this.router.navigate(["../../assets/", assetData.assetId], {
                            queryParams: {
                                fundId: this.fundId,
                                fundReportId: this.fundReportId,
                                fundValuationId: this.fundValuationId,
                                fundInvestmentId: assetData.fundInvestmentId,
                                assetValuationId: assetData.assetValuationId
                            }
                        });
                    }
                }
            }
        };
        return chart;
    }

    private getColor(index: number): string {
        if (index < this.colors.length) {
            return this.colors[index];
        } else {
            return "rgb(200, 200, 200)";
        }
    }

    private readonly colors: string[] = [
        "rgb(100, 149, 237)",
        "rgb(135, 169, 237)",
        "rgb(173, 191, 237)",
        "rgb(196, 208, 236)",
        "rgb(220, 225, 235)",
        "rgb(238, 232, 213)",
        "rgb(238, 221, 187)",
        "rgb(238, 210, 162)",
        "rgb(238, 198, 135)",
        "rgb(238, 187, 102)",
        "rgb(238, 173, 77)",
        "rgb(238, 162, 54)",
        "rgb(238, 149, 44)",
        "rgb(238, 133, 21)",
        "rgb(238, 118, 0)",
        "rgb(238, 106, 20)",
        "rgb(238, 92, 66)",
        "rgb(238, 64, 0)",
        "rgb(238, 59, 59)",
        "rgb(238, 44, 44)"
    ];

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}
